import { ApprovalRequestsBody, ReviewListBody } from '@app/lib/api/post/api.post.model';
import {
  OverviewData,
  ReporterItem,
  ReporterSystemRequest
} from '@app/lib/api/report/api.report.models';
import { Post } from '@app/shared/models/post';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ApprovalRequestsActions = createActionGroup({
  source: 'ApprovalRequests',
  events: {
    // Approval List
    'On Get Approval Requests List': props<ApprovalRequestsBody>(),
    'On Loading Approval Requests List': emptyProps(),
    'On Get More Approval Requests List': props<ApprovalRequestsBody>(),
    'On Select Report Item Approval': props<{
      post: any;
      reportId: string;
    }>(),
    'On Get Overview Report Item': props<{ object_id: string }>(),
    'On Get Approval History Report Item': props<{
      data: ReporterSystemRequest;
      isLoadMore: boolean;
    }>(),
    'On Get Approval History Report Item Verification': props<{
      data: ReporterSystemRequest;
      isLoadMore: boolean;
    }>(),
    'On Loading Report Item': emptyProps(),
    'On Update List': props<{ body: any; isReviewList: boolean }>(),

    // Review List
    'On Get Review List': props<ReviewListBody>(),
    'On Get More Review List': props<ReviewListBody>(),
    'On Select Report Item Review': props<{
      post: any;
      reportId: string;
    }>(),

    'On Get Comments': emptyProps(),

    'On Get Verification Request': emptyProps(),

    'On Get Fanpage': emptyProps(),

    'On Get Group': emptyProps(),

    'On Get Reporter List': props<{ data: ReporterSystemRequest; isLoadMore: boolean }>(),

    'On Select Category': props<{ categories: { type: string }[] }>(),
    'On Remove Category': props<{ category: { type: string } }>(),
    'On Select Module': props<{
      module: {
        moduleName: string;
        objectType: string;
        role: string;
      };
    }>()
  }
});

export const ApprovalRequestsApiActions = createActionGroup({
  source: 'ApprovalRequests Api',
  events: {
    // Approval List
    'On Load Approval Requests Posts Success': props<{ data: any }>(),
    'On Load More Approval Requests Posts Success': props<{ data: any }>(),
    'On Load Approval Requests Posts Fail': props<{ message: string }>(),
    // Review List
    'On Load Review List Posts Success': props<{ data: any; moduleType: string }>(),
    'On Load More Review List Posts Success': props<{ data: any }>(),
    'On Load Review List Posts Fail': props<{ message: string }>(),
    // User profile
    'On Load Approval Requests User Profile Success': props<{ data: any }>(),
    // Comment
    'On Load Approval Requests Comment Success': props<{ data: any }>(),

    'On Load Select Report Item Success': props<{ post: Post; reportId: string }>(),
    'On Load Select Report Item  Fail': props<{ message: string }>(),
    'On Reset Data Censored': emptyProps(),

    'On Update Approval Requests Post Success': props<{ isError: boolean }>(),
    'On Update Approval Requests Post Fail': emptyProps(),

    'On Update Review Requests Post Success': emptyProps(),
    'On Update Review Requests Post Fail': emptyProps(),

    'On Load Comments Success': props<{ data: any }>(),
    'On Load Comments Fail': props<{ message: string }>(),

    'On Load Verification Request Success': props<{ data: any }>(),
    'On Load Verification Request Fail': props<{ message: string }>(),

    'On Load Fanpage Success': props<{ data: any }>(),
    'On Load Fanpage Fail': props<{ message: string }>(),

    'On Load Group Success': props<{ data: any }>(),
    'On Load Group Fail': props<{ message: string }>(),

    // loading for iframe
    'On Set Loading For Iframe': props<{ isIframeContentLoading: boolean }>(),

    // Reporter list
    'On Load Reporter List Success': props<{ reporterList: ReporterItem[]; isLoadMore: boolean }>(),
    'On Load Reporter List Fail': props<{ message: string }>(),
    'On Reset Data Reporter List': emptyProps(),

    'On Delete Item Auto Labeling': props<{ index: number }>(),

    'On Reset Data Approval Requests': emptyProps(),

    // Overview Data
    'On Get Overview Report Item Success': props<{ data: OverviewData }>(),
    'On Get Overview Report Item Fail': props<{ message: string }>(),

    'On Set Status Show Images': props<{ isShowImages: boolean }>(),

    // Approval History
    'On Get Approval History Report Item Success': props<{
      historyList: any[];
      isLoadMore: boolean;
    }>(),
    'On Get Approval History Report Item Fail': props<{ message: string }>(),
    'On Reset Approval History Reporter List': emptyProps(),

    // Modules actions
    'On Reset Role With Module': emptyProps()
  }
});
